import React, { useState } from "react"

export default function AddressTabs() {
  const countries = [
    {
      id: 1,
      name: "India",
      addresses: [
        {
          id: 1,
          name: "Hyderabad",
          address:
            "Cresol Infoserv Pvt. Ltd, Flat no. 303 Fortune Chambers, Silicon Valley, Image Gardens Road, Madhapur, Hyderabad-500081",
          directions: "https://maps.google.com/?q=17.450508,78.385536",
        },
        {
          id: 2,
          name: "Amaravathi",
          address:
            "Cresol Infoserv Pvt. Ltd, NRT Tech Park, Mangalagiri Bypass, Amaravathi, Andhra Pradesh 522503",
          directions: "https://maps.app.goo.gl/QpabtKzrcS98Cxij6",
        },
      ],
    },
    {
      id: 2,
      name: "USA",
      addresses: [
        {
          id: 1,
          name: "Greater Austin TX",
          address:
            "Cresol Infoserv Pvt. Ltd, 3324 Karan Swiss Dr, Leander, Greater Austin TX - 78641",
          directions: "#",
        },
      ],
    },
    {
      id: 3,
      name: "Malaysia",
      addresses: [
        {
          id: 1,
          name: "Kuala Lumpur",
          address:
            "Cresol Infoserv Pvt. Ltd, Unit No. 11-05,Level 11, Menara K1 Commerce One No.1 Lorong 3/137C, Off Jalan Kelang Lama 28200 Kuala Lumpur",
          directions: "#",
        },
      ],
    },
  ]

  const [activeTab, setActiveTab] = useState(countries[0].id)

  const handleTabClick = id => {
    setActiveTab(id)
  }

  const activeCountry = countries.find(country => country.id === activeTab)

  return (
    <div className="md:py-10 py-5 2xl:px-[160px] sm:px-[50px] px-[10px]">
      <h2 className="text-center mb-10 font-semibold">Office Locations</h2>
      <div>
        <ul className="flex justify-center md:gap-10 gap-5">
          {countries.map(country => (
            <li
              key={country.id}
              className={`flex items-center cursor-pointer md:py-3 py-2 md:px-4 px-2 font-semibold ${
                activeTab === country.id
                  ? "bg-[#ed5722] text-white rounded-lg"
                  : ""
              }`}
              onClick={() => handleTabClick(country.id)}
            >
              <h3 className="md:text-[16px] text-[12px] font-medium">
                {country.name}
              </h3>
            </li>
          ))}
        </ul>
        <div className="md:flex justify-center md:gap-10 gap-5 md:my-10 my-5 md:space-y-0 space-y-5">
          {activeCountry.addresses.map(address => (
            <div
              key={address.id}
              className="bg-white text-black max-w-xl rounded-lg md:space-y-5 space-y-3 md:p-4 p-2"
            >
              <h3 className="md:text-2xl">{address.name}</h3>
              <h3 className="md:text-xl">{address.address}</h3>
              <div>
                <a
                  href={address.directions}
                  target="_blank"
                  className="text-[#ed5722] 2xl:text-[16px] text-[12px] font-normal"
                  rel="noopener noreferrer"
                >
                  Directions
                </a>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}
